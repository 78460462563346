import React, { useRef, useEffect, useState } from 'react';
import { gsap } from 'gsap';
import Header from './Header';
import { TextPlugin } from 'gsap/TextPlugin';
import Spline from '@splinetool/react-spline';


gsap.registerPlugin(TextPlugin);

const Object = () => {
useEffect(() => {

  gsap.to('.load2', {
    delay: 1.2,
    duration: 1,
    scaleY: 0.001,
    onComplete: function(){
      gsap.to('.load2', {
        duration: 0.7,
        width: "0px",
        ease: "power1.out",
      });
    }
    });


  

  
  });
  

    return (
     <div className="load">
      <section className="load2"></section>
      </div>

      
    );
  
};



export default Object;
