import '../App.css';
import { gsap } from 'gsap';
import React, { useState, useEffect } from 'react';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';
import { CSSPlugin } from 'gsap/CSSPlugin';
import { useTranslation } from 'react-i18next';

gsap.registerPlugin(CSSPlugin);

export default function Header({ scrollToSection, onContactButtonClick }) { 

  const { t } = useTranslation();

  const [isMenuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (isMenuOpen) {

      gsap.from('.menu-dropdown', {
        opacity: 0,
        x: -8,
        duration: 0.6,
      onComplete: function(){
      gsap.set('.menu-i', { x: 5,});
      gsap.to('.menu-i', {
        opacity: 1,
        x: 0,
        duration: 0.4,
        onComplete: function(){
          gsap.set('.menuLabel', { y: 5,});
          gsap.to('.menuLabel', {
            opacity: 1,
            y: 0,
            duration: 0.4,
          });
        }
      });
      }
    });
    }
  }, [isMenuOpen]);

  const handleMenuClick = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleQuienSoyClick = () => {
    setMenuOpen(false);
    scrollToSection('.title-2');
  };

  const handleProyectosClick = () => {
    setMenuOpen(false);
    scrollToSection('.title-3');
  };

  const handleservices = () => {
    setMenuOpen(false);
    scrollToSection('.services');
  };

  const handleContact = () => {
    onContactButtonClick();
  };


  return (
    <div className='HomeBox0'>
      <button onClick={handleMenuClick} className='menu-button'>
        {isMenuOpen ? <AiOutlineClose className='menu-icon' /> : <button className='menu-icon'>menu</button>}
      </button>

      {isMenuOpen && (
        <div>
          <div className='menu-overlay' onClick={handleMenuClick}></div>
          <div className='menu-dropdown'>
            <section className='menu-padding'>
              <a onClick={handleQuienSoyClick}><span className='menu-i'>1.</span><p className='menuLabel'>&nbsp;{t('home.menuWHO')}</p></a>
              <a onClick={handleservices}><span className='menu-i'>2.</span><p className='menuLabel'>&nbsp;{t('home.menuServices')}</p></a>
              <a onClick={handleProyectosClick}><span className='menu-i'>3.</span><p className='menuLabel'>&nbsp;{t('home.menuProjects')}</p></a>
              <a onClick={() => window.location.href="https://www.gianlucagiordano.dev/como-sitio-web-distintivo-mejora-imagen-negocio-las-palmas-gran-canaria"}><span className='menu-i'>!!</span><p className='menuLabel'>Artículo</p></a>
              <section className='social-menu'>
              <a href='https://www.instagram.com/visual.gio/'><img className='social' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAD2UlEQVR4nO1aSWsUQRSug0vE3WO8ukQ8uN9c8GYQXH6BiMsfcD8n8SqKR0P8A0bBk45L9KbejYkXTYQQZpzp+l5VPJmSN3mFjcxSvcz0CPNBQzPdVa++rldvHaX66KN4aK13A7gF4BURTRORISKX08VzTQMoAbiptd6VOwFr7WEAr3NcdNAF4JW19lBmAs651QAeAliWyX8S0SOt9RnenYWFhfW5fCmlFM+lV3b8LBGNiywmswzggXNuVaqJoyjaCuCNTGaJaKRSqWxSXUKlUtlERKMAlvzu1Gq1LWl2oq5KAH6waqmCYIzZB+CbaMQ759ya4MGsTkJizlo7qAqGtXYQwLys6X6Sg816aY0xB1SPwBhzkNUMwG++bzsgZp1GVI+BiMZkV0otX2Sr4a1TNw92TP5pPpOsRsaY4X+fV6vVzURU5TVqrXc2nYidnRAZ7/Sim8ifj/mQuUbvENGEELnRaqKSTHK2kwvOQgTAeXn+sulERDQjbFOHB+x/tNYXiegZEX2R0MPI/VN+xu80GmuMGWYyTMIYc6qN+n9pRQT8Urlc3piUgHNuHYA7RBQFhB81ALedcwNJ5ZTL5Y0yB1oRqQtKaec/xdTiBYDLPozhC8AQ/8YqEXvvYxo/Re3WmYaIkJjz2w3gaLsxAI55NU7jdClvIqJO9Z0A8DZJLMTnhIimROaHJGpGeRORM8FjphMHdH/J+J25VQgRWUT9YIeoUzMAOOENQNTEmnWUiJjY+sFWGeH9lzHmQteJsE8QIpdURgC4IrKfFEHEO8/dKiPYNLd1ch0kwnCLi4sbVEbwHCKb/msiktaybB0ou69arQ77ZZURAK4Wdtj1X/PbPJwOhBT8ijG/4hBrQuZ4gnX/K/Okd4i1wOigkyHKTKhXbvAxZttmfF0IGgc4FJdxU0nIRFG0jYje90TQ2CCMn+XYSYWp09eeCeM9eCH8VWMJE1fUrwLYw36GL76X3+oH2+9EpxIrZEh1B6QKUwtMdW8659Z2KtWdyVp8YMvDZpSIJqWH4sH3k/wsTe6SqPhQdDkoBLFyUPP0gbe7yAJdCAA8ljVe69mSaTsEl0wZMasyqnoMRHQ3OCvlnp20FZaCyvddgl1pd/yStsL+oEHcsxPm89ba7apgWGu3xxo994IHcuPRq5i03o6ogmCM2Q/gu3e0iZuibOtjZLghOcaHTXX3YN9ldfLpQmr/Iztzn/VSDABbjAkA57hgkEea6yFhzJD4iQlvnVg2q1Pq9nQcRLSXiJ4X8IeBUkcMDttuzhukou7Dj7wWzvjMptUYcz2Koh25E+ijD5UYfwC7kEvCyokWcgAAAABJRU5ErkJggg=="/></a>
              <a href='https://www.linkedin.com/in/gianluca-giordano-78a27a245/'><img className='social' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACpUlEQVR4nO2av2sUQRTHx5+gqNEuiYWVaKGVImhvpWilthY29iYBCxEMNoqN/gW2IukEwQQxCGIKrURELBQDZ47Nzvc7c0bUPHnyFo7D22zinZnD/cLC7Zu3O++zM29mduecq1Wr1prkvT8NYJpkICnrfFBj8d6fWhUEyckEgpcux43KLaEXAFgKIVwJIYy4dVYIYcR7P6YxaWyVWgbAjDorhEtMAMbtIU+v6Kz90UCGXWIKIQxb98KKzkVfdImKVeP7L0C898cBPALQADBPcorkoX4E3DcQm1d+dA6HAL7GGI+4QQEB8NrKJhcXF3c3Go0dOqYbzJN+Bd5zEJJe7SKyvbA1m81d5u/dAIE8tbKbWZYNGcR1s73oV+A9B9E8AND6Q44sxRiPugEbtfYDuA/gI4DPAzlqpSL2IEeel6xIZ6v4iMgWkhdIPgTwyRaBuY6IAG4DOPgvQEqX11V8SL4tKwfwHcAdEdncd5Aye8WH8AbApVartU9Eti4sLOz03p8AcA/AN/OZEpGNyYHo7A9gGcC1bgGq8jw/RvKLtc5EciAkz5E8X5yLyKaS+k/afXKdr5ICUemyBsBdkk174vMAbmnX6vQF8Nh8LicFossaAK/akvpn2+85EdnW7u+9v2jlD5ICAXDVyt6RPKyjkuXDB4MZ7wA5YP7vUwOZU7v3/kyH/ayBvGy3Z1k2VORJUiAkM7Xneb6n3a7ndk3WbheRDQa4nBpI18r/pr5S1SCubhGpu1aZ6hxxdY5InSNl6ucbYll9Va9hDybE2ZIKn1X1Wct9ewqSirgKEKhjCltunYox7q38idZ2cnXJPeYSE4CJyltvutFozkv6whNjHHXrrBjjqEIUX1kqb1MX2wUDvT1dSKmtm/3eHB3IPwzUqlXLFfoF40RwH3bAT2EAAAAASUVORK5CYII="/></a>
              <a onClick={() => window.location.href = 'mailto:info@gianlucagiordano.dev'}><img className='social' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADfklEQVR4nO1ZS2sUQRAeoyR6UFEvufhA8JaDioggXoQE9KAX8RDEm2cj6MEXeIpnc1JBEEXEJ+hVTz4wivoHFBEFDSZmp7+vM8ZnS0FN0s7u7Myuu+MG54Nm2Zrqru/brq6uSYKgRIkSJeY0rLXrAZwgeRbAMfneAZw2KqdzAE5aazekOjvnegBcJOmSA8C1MAyXF8o+CAJjzAqSN1I4XXTOLayaBOCSOkyTPE/ykHwCiNT+DsDWokQA2CYxNfaUZAjJId2VabVf/mOStXZAlRrZRv9ZFEWrST7Wid+ttYedc13tEuCc6wJwXGIpp0dRFK1K8N1AEvq8f+YBydtiNMYcSVl8AcnT3rbet9b2tlqEtbZX1vZSaFhi1/IVrupzc8YIYEyMSeVJJHJ0THayhSIGYh7xqOevmSI8PvpCfooxK2X8HdHPn/V+tTzQ3R6OOQC4l0eIcI05VBHMChr7OefmkTwI4Jsu9iwMw7WNioiiaCXJh/H5I3kqJtgIn6aFxN/DMNxM8o3aQ5J784oAsJvkZxXxXqpU8Jd8/Nzva2jibK2/q4R+ARiROynjvhoRX13vjqyRFScJ4ZoqRO4MAAeqZiX8knZNtSEAX3WdF8aYdUk/sckz9RHfIZmbN04M4Rjfb2k7Eo9bYRgum3FI+AUpkDuI5Cv1kzq/zwu+h2RFRbw1xmxJW4cpccbHxxcDuJLkW0vIoHfRvJ6amtrUiBDBxMTEEgBXvTUv6Ih3/ar41FuDNeIIF+Hk/UiDdQ97pVJZQ/JJspLkFRLDWrufpPUEfJFKl2cuvThehaxK28yqpQfyjHcg7+qhzi1E1+2TwACeZxWSWkJMdSE54xeS3OU3WSIbFdIsOLuLcczPwiXNL1OIQNoWadz8w9XuppF/HuaqprEpIX4b0UlNY0tudh2frLU7ghaB5HYAH2qW1Qw+6YZ/2zTeL0xIm5rGH1rq5xcmpBVNozFmV9uaxqDRiUEQTE5OLiV53Uu7S865RWlrpN1RWXHaLkTgpVr8R4GXtZpG7RpGa3UNHSGkqKaxMCHtbBoLF9LqpvGfC2lF0xh0ipBmwVIIyx1pC/jfpxb0fdg51z1XdsQ516NlfXrGKN2rGo/WE9MpQpxz3fLfNPV76k/s95q4zFGUEOYYVS931tqdoi5OszrjQQFCHtbjoBxHW/mGWqJEiRIlgmbwG7aWCNJTsV1oAAAAAElFTkSuQmCC"/></a>
              </section>
            </section>
          </div>
        </div>
      )}
      <button  onClick={handleContact}  className='insta'  alt="form">{t('home.navContact')}</button>
    </div>
  );
}
