import '../App';

export default function Footer() {
    return (
        <div className='HomeBoxF'>
          <p className='footer-text'>Gianluca Giordano</p>
         <p className='footer-text'>Email: info@gianlucagiordano.dev</p>
         <section>
         <a href='https://www.instagram.com/visual.gio/'><img className='social' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAD2ElEQVR4nO1Zu24TQRSdgqd4UyYtkCAKwqvjIToiJAJfgBCQHwAC1EBaBKIkCj/AQ6KCBAh0QA8hFAEHKZpzJrbJAypYdM1dZWWx8ex612skH2klyztzZ87Ofc41poMOigfJHpJXAYwD+EhykWSQ0bMoMgGMkRwCsCsPAgcBvMhw014PgHGSB5omEATBagD3APxWwXMk75M8JaczOzu7IZMvZYwRWfx74gMkR3StQNe+GwTBqlSCq9XqNgAv9esskbzhnNtsWgTn3GaSN0n+CE+nUqlsTXMSNVUC8E1UyxQEa+1eAF/0g74OgmCN92RRJyVRItllCgbJLgAzSuZOEsMWvVwCsM+0CQDsFzUD8Et++0wIvdMN02YgeUs1ZazRwJ7QO7XSsEMAOCk2KWoEoL/+fblc3gKgrHvcGStIgp2exogpAFi2g5p9/msMyVEdc2UlQWM6aCDPDTdDxDl3Rt8/jxVE8pMOSp0eSPyx1p4D8ATApKYxkn5MAngs72RMDJF+VauStfZEA/WfjN0EgHn9IpuSEiiVSuudc9cBVD1SjwrJa9PT0+uSrkNyk8qYX2lQbaGUfv59ZKPPAFwI0xhNP3rlP1GJyLh3aeIUG+0zDRElUQqP21p7uNEcAEciapw46DJrIqJO4UkAeJUkFxI7ITmhc98mUTNmTURsQjfyMXFCt0ymdjLi+k0RRDQ7rhm2jzrFAcCx0AFUY7xZrkTEjYaGbZpEGL+cc2dbTkRighI5n2DPcbIuqqyHLScS0e2eBHuOk9XbMMjlSGRB7WOjaRIiQ9de+K+JaFkrJ/Ldc+2Oaq1k7BdMkyA5WJix22X3G59Oe0Lvr4pxvxoQK0rmaIJ91695PAyIFc/sILcURVyxb1T+R4oy1bDiy5uIJHqaisu8iSRkqtXqdpJv2iJprE/j5etK7mT81Olz26TxdWTeRgomyZ0GnXO7Jc7II7/lv9Cww5PIpbBCE6WuqIa2HCqepe7Q1NTU2rxK3U/NXj6I5xE3CuCR9lAkA1jQ3scjeZemdkl6+VDodZAPItdB8eWDHHeRF3Q+APBA93ipba9MG8H7ylQQ8So3TZsBwLB3VSo9O20r/PC6vm8RSB4k+VPaCtbaPt9Jd5X5jHOu2xQM51x35F74tvdEaTyGKqatt0OmIFhr+wB8DQNt4qao+PqIvUhD8pYYm2mtYQ+LOoXlQur4I+ylZyd6qcLK2p84LRcGWZS5dXV7r8aJ0Yh3krVvp25PRzE3N7eH5NNGqUfWD4CxXByO+G6pG+SYI+lHVhuXNOaDuFaSl621OzIn0EEHJjH+AKKCFjdJx469AAAAAElFTkSuQmCC"/></a>
         <a onClick={() => window.location.href = 'mailto:info@gianlucagiordano.dev'}><img className='social' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADfklEQVR4nO1ZS2sUQRAeoyR6UFEvufhA8JaDioggXoQE9KAX8RDEm2cj6MEXeIpnc1JBEEXEJ+hVTz4wivoHFBEFDSZmp7+vM8ZnS0FN0s7u7Myuu+MG54Nm2Zrqru/brq6uSYKgRIkSJeY0rLXrAZwgeRbAMfneAZw2KqdzAE5aazekOjvnegBcJOmSA8C1MAyXF8o+CAJjzAqSN1I4XXTOLayaBOCSOkyTPE/ykHwCiNT+DsDWokQA2CYxNfaUZAjJId2VabVf/mOStXZAlRrZRv9ZFEWrST7Wid+ttYedc13tEuCc6wJwXGIpp0dRFK1K8N1AEvq8f+YBydtiNMYcSVl8AcnT3rbet9b2tlqEtbZX1vZSaFhi1/IVrupzc8YIYEyMSeVJJHJ0THayhSIGYh7xqOevmSI8PvpCfooxK2X8HdHPn/V+tTzQ3R6OOQC4l0eIcI05VBHMChr7OefmkTwI4Jsu9iwMw7WNioiiaCXJh/H5I3kqJtgIn6aFxN/DMNxM8o3aQ5J784oAsJvkZxXxXqpU8Jd8/Nzva2jibK2/q4R+ARiROynjvhoRX13vjqyRFScJ4ZoqRO4MAAeqZiX8knZNtSEAX3WdF8aYdUk/sckz9RHfIZmbN04M4Rjfb2k7Eo9bYRgum3FI+AUpkDuI5Cv1kzq/zwu+h2RFRbw1xmxJW4cpccbHxxcDuJLkW0vIoHfRvJ6amtrUiBDBxMTEEgBXvTUv6Ih3/ar41FuDNeIIF+Hk/UiDdQ97pVJZQ/JJspLkFRLDWrufpPUEfJFKl2cuvThehaxK28yqpQfyjHcg7+qhzi1E1+2TwACeZxWSWkJMdSE54xeS3OU3WSIbFdIsOLuLcczPwiXNL1OIQNoWadz8w9XuppF/HuaqprEpIX4b0UlNY0tudh2frLU7ghaB5HYAH2qW1Qw+6YZ/2zTeL0xIm5rGH1rq5xcmpBVNozFmV9uaxqDRiUEQTE5OLiV53Uu7S865RWlrpN1RWXHaLkTgpVr8R4GXtZpG7RpGa3UNHSGkqKaxMCHtbBoLF9LqpvGfC2lF0xh0ipBmwVIIyx1pC/jfpxb0fdg51z1XdsQ516NlfXrGKN2rGo/WE9MpQpxz3fLfNPV76k/s95q4zFGUEOYYVS931tqdoi5OszrjQQFCHtbjoBxHW/mGWqJEiRIlgmbwG7aWCNJTsV1oAAAAAElFTkSuQmCC"/></a>
        </section>
      </div>
    )
  }
  