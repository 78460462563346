import React, { useState } from 'react';
import Spline from '@splinetool/react-spline';
import '../App.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export default function Form ({toHome}) {
  const [formData, setFormData] = useState({
    nombre: '',
    email: '',
    numero: '',
    mensaje: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch('https://formspree.io/f/mbjnnqra', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
    .then(response => {
      if (response.ok) {
        toast.success('The message was sent successfully.');
      } else {
        toast.error('Error sending message, please try again.');
    }
    })
    .catch(error => {
      console.error('Error:', error);
      alert('Error sending message, please try again.');
    });

    setFormData({
      nombre: '',
      email: '',
      numero: '',
      mensaje: ''
    });
  };

  const backToHome = () => {
    toHome();
  };

  return (
    <div className='containerForm'>
      <form className='formStyle' onSubmit={handleSubmit}>
        <input 
          type='text' 
          id='inputText' 
          name='nombre' 
          placeholder='name' 
          value={formData.nombre} 
          onChange={handleChange} 
          required 
        />
        <input 
          type='email' 
          id='inputText' 
          name='email' 
          placeholder='Email' 
          value={formData.email} 
          onChange={handleChange} 
          required 
        />
        <input 
          type='tel' 
          id='inputText' 
          name='numero' 
          placeholder='Phone number' 
          value={formData.numero} 
          onChange={handleChange} 
          required 
        />
        <textarea 
          name="mensaje" 
          placeholder='Describe your next project and how I can help you:' 
          id='inputArea' 
          value={formData.mensaje} 
          onChange={handleChange} 
          required 
        />
        <button className='submit' type="submit">
          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAd0lEQVR4nO2WMQqAQBAD9xOK/mg5kiJfthHUSgufoxxYWeuJuvOBgbAbYhYEZkayIzlJqosGQnIguZFcisrdvQIwH/JVUhvy2/GInXFwKvdqkupcLjl2AOO3xX56r5RSE9LLiHhvxX9zvZlcCo9MHwD9I2MveDU7o4OWrwhu8YUAAAAASUVORK5CYII=" alt="Submit" />
        </button>
      </form>
      <button className='backToHome' onClick={backToHome}>
      <img alt='arrow-back-to-home' src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAa0lEQVR4nO3WMQ6AIAxG4V4Covc/iZPR6KKDx3mGwKKTg5SB/xtZXtI0DWYiHwER2IHJnKMn2eIVDcBRohcwKPobjbcqutneBNhKNB2JaF5oGA6vUY+KV4fGnmnhmnx9VqfsI54u3FyepHM3mdxsTnaFmFMAAAAASUVORK5CYII="/>
      </button>
      <ToastContainer />
    </div>
  );
};


