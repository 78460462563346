import './App.css';
import Header from './components/Header';
import Object from './components/Object';
import Home from './components/Home';
import React, { useState, useEffect } from 'react';
import './components/i18n'; 


function App() {
  
  const [loading, setLoading] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 3000);

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="App">
      {loading ? (
        <div className="container">
          <Object />
        </div>
      ) : (
        <>
          
            <Home />
        </>
      )}
    </div>
  );
}

export default App;
